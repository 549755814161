import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Espècie que per la seva mida passa desapercebuda. Tota ella de color cremós o un poc grisenc.Té un capell que només mesura fins a 2 cm de diàmetre, higròfan i en forma de ventall, davall el qual es troben les làmines reduïdes a plecs connectats els uns amb els altres. Presenta un peu lateral i curt. Les espores són blanquinoses en massa, el·lipsoïdals, de 6-9 x 3,5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      